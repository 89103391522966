import React from 'react';
import './TimelineStyles.css';

const Timeline = ({ events }) => {
	return (
		<div className="timeline">
			{events.map((event, index) => (
				<div
					key={index}
					className={`timeline-event ${index % 2 === 0 ? 'left' : 'right'}`}
				>
					<div className="timeline-content">
						<h2>{event.title}</h2>
						<span className="date">{event.date}</span>
						<p style={{ color: "#000000" }}>{event.description}</p>
					</div>
				</div>
			))}
		</div>
	);
};

export default Timeline;
