import "./AboutContentStyles.css";
import Timeline from "./Timeline";
import React from 'react';

const AboutContent = () => {

	const timelineData = [
        	{
                	title: "Immigration",
                	date: "1846",
                	description: "Christian Germans immigrated from Württemberg, Hanover, Alsace, Baden, and East Frisia and settled in the Quihi area of Media County. Their Christian faith helped them to get through many difficulties and adversities of the Texas Frontier.",
        	},
        	{
                	title: "Gatherings",
                	date: "1852",
                	description: "They began gathering to worship under the live oak trees on this church property and with the help of the Lutheran Pastor, Christian Oefinger, a congregation was founded. Three Texas Synod meetings were hosted at Quihi Bethlehem Church prior to 1894.",
        	},
        	{
                	title: "First Public School of Quihi",
                	date: "1857",
                	description: "The first public school of Quihi had classes held in the church.",
        	},
        	{
                	title: "Cemetery",
                	date: "1864",
                	description: "The adjoining consecrated cemetery was established.",
        	},
        	{
                	title: "Parsonage",
                	date: "1865",
                	description: "Early records show that before 1865 a parsonage was built and expanded.",
        	},
        	{
                	title: "New Parsonage",
                	date: "1874",
                	description: "A new parsonage was built.",
        	},
        	{
                	title: "Storm",
                	date: "1912",
                	description: "The original sanctuary was damaged by a storm.",
        	},
        	{
                	title: "Red Bricks",
                	date: "1913",
                	description: "The present red brick sanctuary was built as a result of the storm damage the previous year.",
        	},
        	{
                	title: "Dedicated",
                	date: "1914",
                	description: "The newly constructed red brick sanctuary was dedicated to the congregation.",
        	},
        	{
                	title: "Parish Hall",
                	date: "1927",
                	description: "A Parish Hall was built.",
        	},
        	{
                	title: "Discontinue Cemetery",
                	date: "1936",
                	description: "The cemetery was discontinued and no longer accepts new burials.",
        	},
        	{
                	title: "New Fountain",
                	date: "1949",
                	description: "St. John's Lutheran Church of New Fountain merged with Quihi Bethlehem Church."
        	},
        	{
                	title: "Even Newer Parsonage",
                	date: "1950",
                	description: "A new parsonage was built until a fire in the kitchen resulted in a new parsonage being built which still stands today.",
        	},
        	{
                	title: "Additions to Parish Hall",
                	date: "1952",
                	description: "Additions were made to include a kitchen, restrooms, and classrooms.",
        	},
        	{
                	title: "Prayer Garden",
                	date: "1952",
                	description: "A Prayer Garden was built where the 1952 parsonage was.",
        	},
        	{
                	title: "Education Building",
                	date: "1972",
                	description: "Additions to the Parish Hall were transformed to what is now known as the Education Building.",
        	},
        	{
                	title: "Fellowship Hall",
                	date: "2001",
                	description: "A large Fellowship Hall was built.",
        	},
        	{
                	title: "Sausage Supper",
                	date: "Every 2nd Saturday of October",
                	description: "Annual Sausage Supper is held on every 2nd Saturday in October since 1924. In 2024, we celebrated 100 years of this tradition.",
        	}
	];

    return (
        <div>
            <div style={{ height: '20px' }}></div>
            <h1 className="centered-text">HISTORY</h1>
            <div style={{ height: '20px' }}></div>
            <Timeline events={timelineData} />
        </div>
    )
};

export default AboutContent;
